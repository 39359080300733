import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import RightArrowLarge from "../../images/icons/RightArrowLarge.svg"

const ServicesWrapper = styled.div`
  min-height: 100vh;
  padding-top: calc(60px + 28px);

  .title {
    color: var(--navy);
    font-family: var(--subheading);
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;

    @media screen and (min-width: 800px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .mobile-only {
    font-size: 24px;
    line-height: 24px;
    @media screen and (min-width: 800px) {
      display: none;
    }
  }

  .introduction {
    color: var(--navy);
    font-family: "Galano Grotesque Medium", sans-serif;
    font-size: 14px;
    line-height: 18px;

    @media screen and (min-width: 800px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .table {
    display: none;

    @media screen and (min-width: 800px) {
      display: grid;
      grid-template-columns: 1fr 3fr;
      margin-bottom: 2rem;
      .arrow {
        color: var(--navy);
      }
      .points {
        list-style: none;
        margin: 0;
        padding: 0 1rem;

        .item {
          color: var(--navy);
          font-family: var(--paragraph);
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 14px;
        }
      }
    }
  }
  .spacer {
    height: 10vh;
  }
`

const AB01Services = ({ setBackground, setNavigation }) => {
  const THRESHOLD = [0, 0.5]
  const [ref, inView] = useInView({
    threshold: THRESHOLD,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView === true) {
      setBackground("var(--light-peach)")
      setNavigation(1)
    }
  })

  return (
    <ServicesWrapper id="services">
      <div ref={ref}></div>
      <h2 className="title mobile-only">
        Our Core Services Typically Involve:
      </h2>
      <h2 className="title">Research</h2>
      <p className="introduction">
        We use research to understand where the gaps are with the existing
        brand.
      </p>
      <div className="table">
        <div className="arrow">
          <RightArrowLarge />
        </div>
        <ul className="points">
          <li className="item">Robust Surveys</li>
          <li className="item">Focus Groups & Interviews</li>
          <li className="item">Online Activity Boards</li>
          <li className="item">Customer Journey Maps</li>
          <li className="item">Competitive Audits</li>
          <li className="item">Whitespace Analysis</li>
        </ul>
      </div>
      <h2 className="title">Brand Strategy</h2>
      <p className="introduction">
        Stand out from the crowd by finding a differentiator that you – and you
        alone – can own.
      </p>
      <div className="table">
        <div className="arrow">
          <RightArrowLarge />
        </div>
        <ul className="points">
          <li className="item">Positioning Statement</li>
          <li className="item">Brand Systems and Hierarchy Strategy</li>
          <li className="item">Brand Identity</li>
          <li className="item">Brand Guidelines</li>
        </ul>
      </div>
      <h2 className="title">Marketing</h2>
      <p className="introduction">
        Convey your differentiator in a way that will resonate with your
        audience.
      </p>
      <div className="table">
        <div className="arrow">
          <RightArrowLarge />
        </div>
        <ul className="points">
          <li className="item">Marketing Communication Plans</li>
          <li className="item">Campaign Planning & Design</li>
          <li className="item">Campaign Execution</li>
          <li className="item">Animation & Video</li>
        </ul>
      </div>
      <h2 className="title">Store Design</h2>
      <p className="introduction">
        We build flagship store and restaurant experiences that embody the true
        essence of your brand.
      </p>
      <div className="table">
        <div className="arrow">
          <RightArrowLarge />
        </div>
        <ul className="points">
          <li className="item">Paint & Paper Executions</li>
          <li className="item">Flagship Design</li>
          <li className="item">Photo Realistic Renders</li>
        </ul>
      </div>
      <h2 className="title">Digital</h2>
      <p className="introduction">
        From websites to paid ads, we want your brand’s online experience to be
        seamlessly engaging.
      </p>
      <div className="table">
        <div className="arrow">
          <RightArrowLarge />
        </div>
        <ul className="points">
          <li className="item">Website Design</li>
          <li className="item">App Design</li>
          <li className="item">Digital Spend Strategy</li>
          <li className="item">Dashboard Creation</li>
        </ul>
      </div>
      <div className="spacer"></div>
    </ServicesWrapper>
  )
}

export default AB01Services

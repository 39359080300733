import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

const ProcessWrapper = styled.div`
  min-height: 100vh;
  padding-top: calc(60px + 28px);

  .section-title {
    color: var(--navy);
    font-family: var(--subheading);
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;

    @media screen and (min-width: 800px) {
      font-size: 24px;
      line-height: 30px;
      text-transform: unset;
    }
  }

  .title {
    color: var(--navy);
    font-family: var(--paragraph);
    font-size: 14px;
    line-height: 18px;

    @media screen and (min-width: 800px) {
      font-family: "Galano Grotesque Medium", sans-serif;
      font-size: 20px;
      line-height: 28px;
    }

    margin-bottom: 14px;

    .number {
      border-bottom: 3px solid var(--navy);
      margin-right: 4px;
      padding: 0 2px;
    }
  }

  .description {
    color: var(--navy);
    font-family: var(--paragraph);
    font-size: 14px;
    line-height: 21px;

    @media screen and (min-width: 800px) {
      font-size: 16px;
      line-height: 24px;
    }

    margin-bottom: 32px;
  }
`

const AB02Process = ({ setBackground, setNavigation }) => {
  const THRESHOLD = [0, 0.5]
  const [ref, inView] = useInView({
    threshold: THRESHOLD,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView === true) {
      setBackground("var(--medium-peach)")
      setNavigation(2)
    }
  }, [inView])

  return (
    <ProcessWrapper id="process">
      <div ref={ref}></div>
      <h2 className="section-title">
        Our projects typically follow 3 key steps:
      </h2>

      <h3 className="title">
        <span className="number">01</span> Build Understanding
      </h3>
      <p className="description">
        Empathy is at the core of our strategy. We’ll work closely with you to
        ensure we understand the problem from both your point of view, but also
        your customers’.
      </p>

      <h3 className="title">
        <span className="number">02</span> Build Solutions
      </h3>
      <p className="description">
        We’ll help you refine and prioritize your current marketing initiatives
        and ideate new creative solutions to build your brand.
      </p>
      <h3 className="title">
        <span className="number">03</span>Build Momentum
      </h3>
      <p className="description">
        We make sure every idea is activated meticulously to ensure your brand
        experience lives up to the vision.
      </p>
    </ProcessWrapper>
  )
}

export default AB02Process

import React, { useState } from "react"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import AB01Services from "./AB01Services"
import AB02Process from "./AB02Process"
import AB03Team from "./AB03Team"

const ContentWrapper = styled.section`
  background: ${props => props.background};
  padding-bottom: 50px;

  @media screen and (min-width: 800px) {
    background: unset;
    padding-bottom: 74px;
  }

  .background {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    height: 90vh;
    position: sticky;
    top: 50px;

    @media screen and (min-width: 800px) {
      background: ${props => props.background};
      top: 60px;
    }
    z-index: -1;
  }

  .navigation {
    margin-top: -85vh;

    @media screen and (min-width: 800px) {
      position: sticky;
      top: 90px;
      width: 20%;
    }

    .sidebar {
      list-style: none;

      #step01,
      #step02,
      #step03 {
        color: var(--navy);

        .link {
          color: var(--navy);
        }
      }

      @media screen and (min-width: 800px) {
        #step01 {
          color: ${props =>
            props.navigation === 1 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 1 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }

        #step02 {
          color: ${props =>
            props.navigation === 2 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 2 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }

        #step03 {
          color: ${props =>
            props.navigation === 3 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 3 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }
      }

      .nav-item {
        .nav-number {
          align-items: center;
          display: flex;
          font-family: "DIN Next LT Pro Regular", sans-serif;
          font-size: 24px;
          line-height: 24px;
        }

        .nav-number::after {
          border: 1px solid;
          content: "";
          display: block;
          margin: 0 0 0 1rem;
          width: 50%;

          @media screen and (min-width: 800px) {
            width: 90%;
          }
        }

        .nav-title {
          font-family: var(--subheading);
          font-size: 30px;
          line-height: 31px;
          text-transform: uppercase;
        }
      }
    }
  }

  .content {
    display: grid;

    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 1fr 2fr;
      margin-top: -47vh;
      padding: 0rem 1rem;
    }

    @media screen and (min-width: 800px) {
      padding: 0 10rem 0 0;
    }
  }
`

const AB00Content = () => {
  const [background, setBackground] = useState("var(--light-peach)")
  const [navigation, setNavigation] = useState(1)

  return (
    <ContentWrapper navigation={navigation} background={background}>
      <Grid>
        <div className="background"></div>
        <div className="navigation">
          <ul className="sidebar">
            <li id="step01" className="nav-item">
              <a className="link" href="#services">
                <h3 className="nav-number">01</h3>
                <h4 className="nav-title">Our Services</h4>
              </a>
            </li>
            <li id="step02" className="nav-item">
              <a className="link" href="#process">
                <h3 className="nav-number">02</h3>
                <h4 className="nav-title">Our Process</h4>
              </a>
            </li>
            <li id="step03" className="nav-item">
              <a className="link" href="#team">
                <h3 className="nav-number">03</h3>
                <h4 className="nav-title">Our Team</h4>
              </a>
            </li>
          </ul>
        </div>
        <div className="content">
          <div className="blank"></div>
          <div className="blank"></div>
          <div className="content-wrapper">
            <AB01Services
              setBackground={setBackground}
              setNavigation={setNavigation}
            />
            <AB02Process
              setBackground={setBackground}
              setNavigation={setNavigation}
            />
            <AB03Team
              setBackground={setBackground}
              setNavigation={setNavigation}
            />
          </div>
        </div>
      </Grid>
    </ContentWrapper>
  )
}

export default AB00Content

import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import PageHero from "../components/Layout/PageHero"
import AB00Content from "../components/About/AB00Content"

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <PageHero
        title="About"
        description="We build brands on the foundation of empathy. Understanding the intricacies of your business – and your customer – helps us find the position you can uniquely own."
        videoUrl="https://videos.ctfassets.net/d0u2w5k6pl1p/24xcTyoCYun4ceaxXNBbd9/66fff9b2a63ba79e3ea99daeb290bda8/About.mp4"
      />
      <AB00Content />
    </Layout>
  )
}

export default AboutPage

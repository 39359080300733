import React from "react"
import styled from "styled-components"
import Grid from "./Grid"

const HeroWrapper = styled.section`
  .hero-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .video-wrapper {
      position: absolute;
      bottom: 4rem;
    }
    .background-video {
      height: auto;
      width: 100vw;

      @media screen and (min-width: 800px) {
        width: 60vw;
      }
    }

    .caption {
      background: white;
      position: absolute;
      top: 15%;
      left: 0;
      width: 80vw;
      z-index: 2;
      @media screen and (min-width: 800px) {
        left: 3rem;
        padding: 2rem;
        width: 25vw;
      }

      .section-title {
        color: var(--navy);
        font-family: var(--subheading);
        font-size: 40px;
        line-height: 40px;
        margin: 0;

        @media screen and (min-width: 800px) {
          font-size: 70px;
          line-height: 71px;
        }
      }

      .section-description {
        color: var(--navy);
        font-family: "DIN Next LT Pro Regular", sans-serif;
        font-size: 24px;
        line-height: 30px;
        margin: 0;

        @media screen and (min-width: 800px) {
          font-size: 33.6px;
          line-height: 40px;
        }
      }
    }
  }
`

const PageHero = ({ title, description, videoUrl }) => {
  return (
    <HeroWrapper>
      <Grid>
        <div className="hero-wrapper">
          <div className="video-wrapper">
            <video className="background-video" loop muted autoPlay>
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>

          <div className="caption">
            <h1 className="section-title">{title}</h1>
            <p className="section-description">{description}</p>
          </div>
        </div>
      </Grid>
    </HeroWrapper>
  )
}

export default PageHero

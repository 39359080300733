import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const TeamWrapper = styled.div`
  min-height: 100vh;
  padding-top: calc(60px + 28px);
  padding-bottom: 5rem;

  .intro {
    margin-bottom: 3rem;
  }

  .team-title {
    color: var(--navy);
    font-family: var(--subheading);
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;

    @media screen and (min-width: 800px) {
      font-size: 24px;
      line-height: 30px;
      text-transform: unset;
    }
  }

  .section-title {
    color: var(--navy);
    font-family: var(--subheading);
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;

    @media screen and (min-width: 800px) {
      text-transform: unset;
    }
  }

  .description {
    color: var(--navy);
    font-family: var(--paragraph);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 14px;

    @media screen and (min-width: 800px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .team-title {
    text-transform: uppercase;
  }

  .team-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @media screen and (min-width: 800px) {
      all: unset;
    }

    .member-wrapper {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-rows: 0.7fr 1fr;

      @media screen and (min-width: 800px) {
        grid-template-columns: 2fr 3fr;
        grid-template-rows: unset;
        grid-gap: 2rem;
      }
      margin-bottom: 3rem;

      .image-wrapper {
        margin-bottom: 1rem;
      }

      .caption-wrapper {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 800px) {
          justify-content: center;
        }
        .caption-title {
          color: var(--navy);
          font-family: var(--heading);
          font-size: 12px;
          line-height: 14px;
          margin: 0;

          @media screen and (min-width: 800px) {
            font-size: 16px;
            line-height: 18px;
          }
        }

        .mobile-only {
          @media screen and (min-width: 800px) {
            display: none;
          }
        }
        .caption {
          color: var(--navy);
          font-family: var(--paragraph);
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 14px;

          @media screen and (min-width: 800px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .collective-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .introduction {
      color: var(--navy);
      font-size: 14px;
      line-height: 18px;
      grid-column: 1 / 3;
      margin-bottom: 2rem;

      @media screen and (min-width: 800px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .left,
    .right {
      list-style: none;
      padding: 0;
      margin: 0;

      .item {
        color: var(--navy);
        font-family: var(--heading);
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 14px;
        @media screen and (min-width: 800px) {
          font-family: var(--paragraph);

          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
`

const AB03Team = ({ setBackground, setNavigation }) => {
  const THRESHOLD = [0, 0.5]
  const [ref, inView] = useInView({
    threshold: THRESHOLD,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView === true) {
      setBackground("var(--dark-peach)")
      setNavigation(3)
    }
  })

  const data = useStaticQuery(graphql`
    query {
      mike: file(relativePath: { eq: "mike.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      michelle: file(relativePath: { eq: "michelle.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <TeamWrapper id="team">
      <div ref={ref}></div>

      <div className="intro">
        <h2 className="section-title">We're always up for a challenge.</h2>
        <p className="description">
          We’ve worked with everyone from start-ups to Fortune 500 brands.
        </p>
        <p className="description">
          Think of us as that youthful breath of fresh air your organization
          needs to power into the future.
        </p>
        <p className="description">
          We have big visions and a whole lot of energy to pull them off. We
          also draw on our A-list collective of creative talent as-needed to
          bolster your team where your brand needs it most.
        </p>
      </div>
      <div className="team-members">
        <h2 className="team-title">Our Team</h2>

        <div className="team-wrapper">
          <div className="member-wrapper">
            <div className="image-wrapper">
              <Img fluid={data.mike.childImageSharp.fluid} />
            </div>
            <div className="caption-wrapper">
              <p className="caption-title">This is Mike.</p>
              <p className="caption-title mobile-only">
                Project Lead & Strategist
              </p>
              <p className="caption">
                Your project lead and strategist, Mike has a strong track record
                of transforming everything from startups to fortune 500 brands.
                Mike’s ability to understand his clients’ needs and their
                customers’ desires has helped him engineer transformative
                business growth.
              </p>
            </div>
          </div>
          <div className="member-wrapper">
            <div className="image-wrapper">
              <Img fluid={data.michelle.childImageSharp.fluid} />
            </div>
            <div className="caption-wrapper">
              <p className="caption-title">This is Michelle.</p>
              <p className="caption-title mobile-only">Designer & Strategist</p>
              <p className="caption">
                As your lead designer and strategist, Michelle draws on her rare
                ability to balance customer insights, data, and seasoned
                intuition. Her meticulous execution across retail, education,
                and hospitality has led to award-winning designs that drive
                business growth.
              </p>
            </div>
          </div>
        </div>

        <div className="collective-wrapper">
          <p className="introduction">
            We'll curate the best team for your brand. Our talented collective
            includes:
          </p>
          <ul className="left">
            <li className="item">Copywriters</li>
            <li className="item">Brand Strategists</li>
            <li className="item">Leaders</li>
            <li className="item">Dog lovers</li>
            <li className="item">Animators</li>
            <li className="item">Store Designers</li>
          </ul>
          <ul className="right">
            <li className="item">Production Artists</li>
            <li className="item">Taco Connoisseurs</li>
            <li className="item">UX/UI Designers</li>
            <li className="item">Developers</li>
            <li className="item">Graphic Designers</li>
            <li className="item">Researchers</li>
          </ul>
        </div>
      </div>
    </TeamWrapper>
  )
}

export default AB03Team
